
@keyframes blurIn {
  0% {
    opacity: 0;
    filter: blur(3px);
  }
  50% {
    filter: blur(3px);
  }
  100% {
    opacity: 1;

    filter: blur(0px);
  }
}

.fill-white {
  fill: #FFFFFF;
}

.stroke-white {
  stroke: #FFFFFF;
}

.stroke-round {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 8px;
}


.fill-red{
  fill: #FB7777;
}

.stroke-red{
  stroke: #FB7777;
}

.fill-none{
  fill: none;
}



.fill-area{
  width: 100%;
  height: 100%;
}

.browseSVG {
  stroke: #FFF;
  stroke-width: 20px;
  fill: #FFF;
  fill-opacity: .1;
}



svg.doc_symbol .a {
  fill: #7A7B7F;
}

svg.doc_symbol .b {
  fill: #FFFFFF;
}
