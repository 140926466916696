div.loading {
  width: 100vw;
  height: calc(100vh - 50px);
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;

}

div.loading > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 75px;

}


div.loading > div > div {
  display: block;
  margin-bottom: 6px;
  height: 6px;
  background-color: #FFF;
  width: 10px;
}
