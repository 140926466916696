



form.archtitle input[type='text'] {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: none;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  text-transform: uppercase;
  transition: all 400ms ease-out 10ms;
  height: 1em;
  width: calc(100% - 55px);
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  padding-right: 10px;
  padding-left: 20px;
}

.deskhead-text-box{

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.deskhead-text-name{
  margin: 5px 0px;
  color: #B0B0BF;
  font-size: 12px;
}
.deskhead-text-id{
  color: #84849A;
  font-size: 10px;
}

.desk-head svg{
  margin: 0px 16px 0px 4px;
  width: 13px;
}
