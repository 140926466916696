section.me {
  width: 210px;
  display: flex;
  justify-content: center;
  top: 0px;
  position: fixed;
  transition: left 300ms ease-out;
}

div.me {
  width: 200px;
  height: calc(100vh - 20px);
  min-height: 600px;
  margin: 10px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #26262F;
  border-radius: 10px;
}

div.me-controls {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 2;
}

div.logo {
}

div.logo svg {
  width: 25px;
  margin: 6px;
}

div.logo svg:hover, div.me-button svg:hover  {
  opacity: .6;
}

button.me-button{
  border: 2px solid rgba(255,255,255,0);
  background: none;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  padding: 6px;
  /* transition: all 300ms; */
}

button.me-button svg {
  width: 25px;

}


.blurr {
  animation: 400 blurIn;
  animation-timing-function: ease-in;
}

h1.me{
  font-size: 10px;
  height: 10px;
  color: #D2D2DA;
  margin-bottom: 10px;
  margin-top: 120px;

}

div.me-status{
  width: 100%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

}

div.me-status > svg{
  width: 12px;
  margin: 0px 10px;

}

h3.me-status{
  font-size: 10px;
  height: 12px;
  letter-spacing: 1px;
  color: #A5A5B6;
  vertical-align: middle;
  margin-right: 10px;
}

section.my{
  width: 100%;
  min-height: 200px;
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


div.my {
  width: 80%;
  height: .5px;
  margin: 10px 0px;
  background-color: #65657B;
}

div.create {
  padding: 5px 10px;
  height: 20px;
  margin-top: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 170ms ease-in 30ms;
  cursor: pointer;
}

div.create:hover {
  background-color: #5C5C70;
}

div.create > svg {
  pointer-events: none;
  height: 80%;

}

p.create {
  pointer-events: none;
  margin-left: 10px;
  /* padding-top: .5px; */
  font-size: 12px;
  font-weight: 400;

}


div.my-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}




/* ======================================================== */







section.my div.book-cover {
  width: 90%;
  padding: 2px;
  margin-bottom: 15px;
  border-radius: 10px;
  transition: background-color 170ms ease-in 30ms;
  animation: .15s blurIn;
  animation-timing-function: ease-in;
}



section.my  div.book svg {
  width: 18px;
  height: 18px;
  margin: 2px;
}


section.my  div.book-info {
  width: calc(100% - 22px);
}

section.my  div.book-title{
  width: 100%;
}

section.my  div.book-id{
  width: 90%;
  font-size: 9px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow: ellipsis;
}
