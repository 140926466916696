


form.archtitle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

}

form.archtitle input[type='text']::placeholder  {
  color: #C7C7D1;

}

form.archtitle input[type='text'] {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: none;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  text-transform: uppercase;
  transition: all 400ms ease-out 10ms;
  height: 1em;
  width: calc(100% - 55px);
  white-space:nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  font-weight: 500;
  padding-right: 10px;
  padding-left: 20px;
}
