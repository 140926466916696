form.addtag {
  border-radius: 10px;
  display: flex;
  margin-left: 15px;
  padding: 10px;
}

/* form.addtag input[type='text'] {
  height: 100%;
  width: 100%;
  padding: 5px 12px;
  text-align: left;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
  vertical-align: bottom;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.4px;
  background-color: #34343f;
  color: white;
  border-radius: 10px;
} */

/* form.addtag input[type='text']:focus {
  background-color: #49495A;
} */
