@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700&display=swap');


main.about, main.home  {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Roboto Slab', serif;
  color: #333;
}

@media screen and (min-width: 900px) {
  main.about, main.home  {
    max-width: 800px;
    width: calc(100vw - 350px);
    min-height: 100vh;
    left: 350px;
  }
}


.about p {
  padding: 0px 40px;
  font-size: 14pt;
}


.logosvg1 {
  height: 23pt;
  margin-bottom: -2px;
  margin-right: 4px;
}

.logosvg2 {
  height: 14pt;
  margin-bottom: -2px;
  margin-right: 2px;
}

main.about > svg {
  padding: 0px 60px 0px 40px;
}

main.home {
  justify-content: center;
}

main.about .contact{
  text-transform: uppercase;
  padding: 40px;
  font-weight: 700;
  font-size: 30pt;
}

main.about .contact > a {
  color: #000;
  transition: all 300ms ease-out 10ms;
}

main.about .contact > a:hover {
  letter-spacing: 2px;
  color: #1977f3;
}

svg.logo {
  padding: 0px;
  width: 100px;
  align-self: center;
}

main.home h1, main.home h1 {
  padding: 0px 40px;
  text-align: center;
}

span.logo {
  font-family: 'Arimo', sans-serif;
  color: #000;
  font-weight: 700;
  text-decoration: none;
}



footer.about {
  margin-top: 80px;
  border-top: 1px solid #CCC;

  width: 100%;
  height: 200px;
  margin-top: 80px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

footer.about > div {
  width: 50%;
  display: flex;
  justify-content: center;
}


footer.about a {

}

footer.about .pitl a {
  width: 60%;
}

footer.about .dpl a {
  width: 75%;
}

footer.about .tasc a {
  width: 72%;
}

footer.about .shc a {
  width: 85%;
}

footer.about a:hover {
  opacity: .6;

}

footer.about svg{
  width: inherit;
}

footer.about img {
  width: inherit;
}
