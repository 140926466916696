
header.curtain {
  width: 350px;
  height: 100vh;
  border-right: solid 1px black;
  border-bottom: none;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
  background-color: #FFF;
}



.link {
  font-weight: 500;
  color: #000;
  text-decoration: underline;
}


header.curtain div.logo {
  display: block;
  width: 50px;
  height: 80vh;
  margin-left: 50px;
  overflow: hidden;
}

div.logo > div {
  display: block;
  margin-bottom: 4px;
  height: 4px;
  background-color: #3975EB;
  margin-left: 0px;
  width: 50px;
}


div.logo > div:nth-child(-n+5) {
  background-color: #3975EB;

}


div.nav-box{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

nav.lobby {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  text-decoration: none;
}

nav.lobby button {
  border: none;
  background: none;
  font-family: 'Oswald', sans-serif;
  padding: 0;
  letter-spacing: 0px;
  transition: letter-spacing 300ms ease-out 10ms;
  text-transform: uppercase;
  font-size: 30pt;
  font-weight: 600;
  opacity: 0;
  color: #000;
  margin: 0px;
  text-align: left;
}


.swipe {
  width: 350px;
  height: 100vh;
  position: absolute;
  background: rgb(57,117,235);
  background: linear-gradient(164deg, rgba(57,117,235,1) 0%, rgba(38,88,187,1) 100%);
  z-index: 2;
}


.access:focus {
  outline: none;
  border: 2px solid #061F40;
  letter-spacing: 3px;
}




nav.lobby a:nth-child(-n + 2) button {
  color: #666;
}

nav.lobby button:hover {
  letter-spacing: 3px;
}

nav.lobby > a > button:focus{
  outline: none;
  border: 3px solid #061F40;
  letter-spacing: 3px;
  box-shadow: inset 2px 2px 4px #cfcfcf,
              inset -2px -2px 4px #ffffff;
}

main *:focus{
  outline: none;
  border: 3px solid #061F40;
}
