
section.search {
  min-height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
  padding: 0px;
}


form.search input[type='text'] {
  max-width: 600px;
  width: calc(100vw - 240px);
  height: 60px;
  padding: 0px 20px;
  margin-left: 20px;
  border-radius: 10px;
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
  vertical-align: bottom;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.6px;
  background: none;
  background-color: #49495A;
  color: white;
}
