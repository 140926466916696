

@media screen and (max-width: 900px) {



  main.home, main.about {
    top: 100px;
    width: 100vw;
    max-width: 100vw;
    min-height: calc(100vh - 100px);
    left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  div.curtain {
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  header.curtain {
    width: 100vw;
    height: 100px;
    border-bottom: solid 1px black;
    border-right: none;
    justify-content: center;
    align-items: center;
  }

  div.nav-box{
    margin-left: 0px;
  }

  header.curtain div.logo {
    display: none;
  }

  header.curtain div.logo > div {
    display: none;
  }

  nav.lobby {
    flex-direction: row;
    justify-content: center;
  }

  nav.lobby button {
    margin: 10px;
    font-size: 4vw;
  }





  svg.logo {
    padding: 0px;
    width: 10vw;
  }


  .swipe {
    top: 0;
    height: 100px;
    width: 100vw;
  }


  .fade-exit main{
    opacity: 1;
  }

  .fade-exit-active main{
    opacity: 0;
    transition: opacity 1ms ease-in 700ms;
  }

  .fade-enter main{
    opacity: 0;
  }

  .fade-enter-active main{
    opacity: 1;
    transition: opacity 1ms ease-in 701ms;
  }

  .fade-enter .swipe{
    animation-timing-function:  cubic-bezier(0.5 0.0, 0.5, 1.0);
    animation: smallswipe 1400ms;
  }

}

@media screen and (max-width: 500px) {

  /* h1 {
    padding: 30px;
    font-weight: 400;
    font-size: 5vw;
    line-height: 7vw;
  } */


  nav.lobby button {
    margin: 10px;
    font-size: 6vw;
  }



  svglogo {
    padding: 0px;
    width: 25vw;
  }





}
