form.addto {
  height: 32px;
  display: flex;
  align-items: center;

}

div.addto {
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 10px;
  border-radius: 10px;
  background-color: #34343F;
  transition: all 100ms ease-out 10ms;

}

div.addto:hover{
  background-color: #5C5C70;
}


div.addtobutton {
  margin-left: 10px;
  display: flex;
  align-items: center;
  padding: 1px;
}



div.addtobutton > svg{
  height: 16px;
  width: 16px;
}

div.addtobutton > p{
  margin-left: 5px;
  color: #C7C7D1;
}

div.drop{
  height: 100%;
  align-self: flex-start;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  margin: 0px 5px;

}

div.droptop{
  position: relative;
  margin-bottom: .2px;
}


div.droptop input.id {
  display: none;
}

div.droptop input.title {
  width: 80px;
  height: 1em;
  appearance: none;
  border: none;
  outline: none;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  letter-spacing: 1.6px;
  font-size: 12px;

  font-weight: 500;
  text-transform: uppercase;
  color: white;
  background: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}


div.droptop svg {
  width: 12px;
}

div.dropdown {
  height: 14px;
}

div.dropdown > div{
  display: inline-block;
  padding: 0px 10px;
  color: #C7C7D1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  letter-spacing: 1.2px;
  font-size: 11px;
  opacity: 0;
  transition: letter-spacing 200ms ease-in 20ms;

}

div.dropdown > div:hover{
  letter-spacing: 1.8px;
  color: #E9E9ED;
}


form.addto > svg {
  width: 14px;
  cursor: pointer;
  padding: 5px;
}
